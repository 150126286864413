<template>
  <a-card>
    <a-form-model
        ref="formState"
        :model="formState"
        layout="inline"
        :rules="rules"
    >
      <p class="title_text">工单信息</p>
      <a-divider/>
      <a-row>
        <a-col :span="1">
        </a-col>
        <a-col :span="7">
          <a-form-item label="下单人">
            <span>{{ formState.creatorName }}</span>
          </a-form-item>
        </a-col>
        <a-col :span="7">
          <a-form-item label="下单日期">
            <span>{{ formState.orderDate }}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row style="padding-top: 12px">
        <a-col :span="1">
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="品 牌" prop="principalId">
            <a-select
                v-model="principalId"
                show-search
                option-filter-prop="children"
                allow-clear
                label-in-value
                placeholder="请输入关键字搜索"
                :filter-option="filterOption"
                style="width: 200px"
                @change="changePrincipalId"
            >
              <a-select-option value="0">
                不限
              </a-select-option>
              <a-select-option :value="item.id" v-for="item in principal_list" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="类 型" prop="orderTypeId">
            <a-select
                v-model="formState.orderTypeId"
                allow-clear
                placeholder="请选择"
                style="width: 200px"
            >
              <a-select-option :value="item.id" v-for="item in orderTypeId_list" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="下单模板数量" prop="guideshootTotal">
            <a-input-number class="input" :min="1" :precision="0" v-model="formState.guideshootTotal"/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row style="padding-top: 12px">
        <a-col :span="1">
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="脚本提交截止日期" prop="scriptSubmitDeadline">
            <a-date-picker v-model="formState.scriptSubmitDeadline" style="width: 170px" value-format="YYYY-MM-DD"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="成片提交截止日期" prop="productSubmitDeadline">
            <a-date-picker v-model="formState.productSubmitDeadline" style="width: 170px" value-format="YYYY-MM-DD"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="模板上线截止日期" prop="guideshootPublishDate">
            <a-date-picker v-model="formState.guideshootPublishDate" style="width: 170px" value-format="YYYY-MM-DD"/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-divider/>
    </a-form-model>
    <div>
      <a-row>
        <a-col :span="12">
          <p class="title_text">模板内容</p>
        </a-col>
        <a-col :span="7">
          <a-col class="tools">
            <a-button
                type="primary"
                @click="actionButtons(1)"
            >添加
            </a-button>
          </a-col>
        </a-col>
      </a-row>
      <templateList
          :columns="columns"
          :content-list="contentList"
          @actionChange="actionChange"
          :title_name="''"
          :list-name="'WorkOrderManagementPage'"
      />
      <!--  <a-table
            :columns="columns"
            :data-source="contentList"
            :pagination="false"
            :rowKey="(record, index) => index"
            :loading="loading"
            bordered
            :scroll="{ y: 500,x:1000 }"
        >
          <div slot="status" slot-scope="text, record">
            <div v-if="record.status == 1 || record.status == 3 || record.status == 5 ||record.status == 7">
              <a-badge color="blue"/>
              <span style="color:#2A90F4;">
              {{
                  record.status == 1 ? "草稿中" : record.status == 3 ? "策划中" : record.status == 5 ? "成片中" : record.status == 7 ? "上线中" : ""
                }}
            </span>
            </div>
            <div v-if="record.status == 2 || record.status == 4 || record.status == 6 ||record.status == 8">
              <a-badge color="#ff4446"/>
              <span style="color: #ff4446">
              {{
                  record.status == 2 ? "待接单" : record.status == 4 ? "待定稿" : record.status == 6 ? "待审核" : record.status == 8 ? "待验收" : ""
                }}
            </span>
            </div>
            <div v-if="record.status == 9">
              <a-badge color="#0bc9b9"/>
              <span style="color: #0bc9b9">已完成</span>
            </div>
          </div>
          <div slot="operation" slot-scope="text, record">
            <a @click="actionButtons(2,record)">编辑</a>
          </div>
        </a-table>-->
    </div>
    <div class="template_box">
      <a-button
          @click="save"
          class="mr-10"
      >保存
      </a-button>
      <a-button
          @click="handleButtons"
          class="mr-10"
      >取消
      </a-button>
      <a-button
          type="primary"
          @click="SubmitButtons"
      >提交
      </a-button>
    </div>

    <!--添加/编辑模板内容-->
    <a-modal v-model="templateVisible" :title="templateTitle" @ok="handleOk" width="40%">
      <a-form-model
          ref="formRef"
          :model="formModel"
          layout="inline"
          :rules="formModelRules"
      >
        <a-row style="padding-bottom: 12px">
          <a-col>
            <a-form-model-item label="车系" prop="seriesId">
              <a-select
                  v-model="formModel.seriesId"
                  allow-clear
                  show-search
                  placeholder="请选择"
                  style="width: 400px"
                  @change="changeSeriesId"
                  :filter-option="filterOption"
              >
                <a-select-option value="0">
                  不限
                </a-select-option>
                <a-select-option :value="item.id" v-for="item in carSeriesList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px">
          <a-col>
            <a-form-model-item label="模板数量" prop="guideshootTotal">
              <a-input-number
                  style="width: 400px"
                  :min="0"
                  :precision="0"
                  v-model="formModel.guideshootTotal"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px">
          <a-col>
            <a-form-model-item label="车系卖点">
              <a-textarea
                  :rows="4"
                  style="width: 400px"
                  placeholder="请输入"
                  v-model="formModel.seriesDescription"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px">
          <a-col>
            <a-form-model-item label="内容类型">
              <a-input
                  style="width: 400px"
                  placeholder="请输入"
                  v-model="formModel.contentType"/>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import moment from "moment";
import _api from "@/api/contentProduction.js";
import _ from "lodash";
import { isEqual } from "lodash-es";
import templateList from "./components/template_list";

export default {
  name: "WorkOrderManagementPage",
  data() {
    return {
      id: this.$route.query.id,
      type: this.$route.query.type,
      formState: {
        creatorName: "",
        orderDate: "",
      },
      rules: {
        principalId: [
          {required: true, message: "请选择品牌", trigger: "change"}
        ],
        orderTypeId: [
          {required: true, message: "请选择类型", trigger: "change"}
        ],
        guideshootTotal: [
          {required: true, message: "请输入下单模板数量", trigger: "blur"}
        ],
        scriptSubmitDeadline: [
          {required: true, message: "请选择时间", trigger: "change"}
        ],
        productSubmitDeadline: [
          {required: true, message: "请选择时间", trigger: "change"}
        ],
        guideshootPublishDate: [
          {required: true, message: "请选择时间", trigger: "change"}
        ],
      },
      principal_list: [],
      orderTypeId_list: [],
      columns: [
        {
          dataIndex: "seriesName",
          title: "车系"
        },
        {
          dataIndex: "guideshootTotal",
          width: 130,
          title: "模板数量"
        },
        {
          dataIndex: "seriesDescription",
          title: "车系卖点"
        },
        {
          dataIndex: "contentType",
          title: "内容类型"
        },
        {
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
          width: 100,
          fixed: "right",
          title: "操作"
        }
      ],
      contentList: [],
      loading: false,
      templateVisible: false,
      templateTitle: "添加模板内容",
      formModel: {
        seriesId: undefined,
        guideshootTotal: null,
        id: null,
        seriesDescription: undefined,
        seriesName: undefined,
        principalId: null
      },
      formModelRules: {
        seriesId: [
          {required: true, message: "请选择车系", trigger: "change"}
        ],
        guideshootTotal: [
          {required: true, message: "请输入", trigger: "blur"}
        ],
      },
      seriesId_list: [],
      principalId: {},
      carSeriesList: [],
    };
  },
  components: {templateList},
  mounted() {
    this.getPrincipalsList();
    this.getOrderTypeIdList();
    this.formState.creatorName = this.$root.user.realname;
    this.formState.orderDate = moment().startOf("days").format("YYYY-MM-DD");
    if(this.id) {
      this.getOrderData();
    }
  },
  methods: {
    getOrderData() {
      _api.getOrderData(this.id).then(res => {
        if(res.code == 200) {
          let data = res.data || {};
          this.$set(this.formState, "creatorName", data.creatorName);
          this.$set(this.formState, "orderDate", data.orderDate);
          this.$set(this.formState, "principalId", data.principalId);
          this.$set(this.formState, "principalName", data.principalName);
          this.$set(this.formState, "orderTypeId", data.orderTypeId);
          this.$set(this.formState, "productSubmitDeadline", data.productSubmitDeadline);
          this.$set(this.formState, "scriptSubmitDeadline", data.scriptSubmitDeadline);
          this.$set(this.formState, "guideshootPublishDate", data.guideshootPublishDate);
          this.$set(this.formState, "guideshootTotal", data.guideshootTotal);
          this.$set(this.principalId, "key", data.principalId);
          this.contentList = (data.contentList || []).map(item => {
            item.principalId = data.principalId;
            item.principalName = data.principalName;
            return item;
          });
          this.getSeriesIdList(data.principalId);
          console.log(this.formState);
        } else {
          this.$message.error(res.message || "获取工单详情失败");
        }
      });
    },// 详情
    getPrincipalsList() {
      _api.getPrincipalsList().then(res => {
        if(res.code == 200) {
          this.principal_list = res.data || [];
        } else {
          this.principal_list = [];
        }
      });
    }, // 品牌列表
    getOrderTypeIdList() {
      _api.getOrderTypeIdList().then(res => {
        if(res.code == 200) {
          this.orderTypeId_list = res.data || [];
        } else {
          this.orderTypeId_list = [];
        }
      });
    }, // 类型列表
    getSeriesIdList(id) {
      console.log(id);
      _api.getSeriesIdList({principalId: id}).then(res => {
        if(res.code == 200) {
          // this.seriesId_list = res.data || [];
          this.carSeriesList = res.data || [];
        } else {
          this.seriesId_list = [];
        }
      });
    }, // 车系列表
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }, // 品牌过滤
    changePrincipalId(val) {
      this.$set(this.formState, "principalId", val.key.trim());
      this.$set(this.formState, "principalName", val.label.trim());
      console.log(this.formState);
      this.principalId = val;
      if(val.key == "0") {
        this.carSeriesList = [];
        this.contentList = this.contentList.map(item => {
          item.seriesId = "0";
          item.seriesName = "不限";
          return item;
        });
      } else {
        this.getSeriesIdList(val.key);
      }
    },// 品牌选择
    changeSeriesId(val) {
      console.log(val);
      this.formModel.seriesId = val;
      if(val == 0) {
        this.formModel.seriesName = "不限";
      } else {
        this.formModel.seriesName = this.carSeriesList?.find(item => isEqual(item.id, val))?.name;
      }
    },// 车系选择
    actionButtons(type, row) {
      this.formModel = {
        seriesId: undefined,
        guideshootTotal: null,
        id: null,
        seriesDescription: undefined,
        seriesName: undefined,
        principalId: null
      };
      switch(type) {
        case 1:
          if(this.formState.principalId) {
            this.formModel.principalId = this.formState.principalId;
            this.templateVisible = true;
            this.templateTitle = "添加模板内容";
          } else {
            this.$message.error("请先选择品牌");
          }
          break;
        case 2:
          this.templateTitle = "编辑模板内容";
          this.formModel = {...row};
          this.$set(this.formModel, "principalId", row.principalId);
          if(this.formState.principalId != 0 && this.formModel.principalId) {
            this.getSeriesIdList(this.formModel.principalId);
          }
          // if(this.type == "edit") {
          //
          // }
          this.templateVisible = true;
          break;
      }
      // if([1, 2].includes(type)) isEmpty(this.handleGetSeriesList()) && this.getSeriesIdList(this.formState.principalId);
    },// 操作
    handleOk() {
      this.$refs.formRef.validate((valid) => {
        if(!valid) {
          return;
        }
        console.log(this.formModel);
        if(this.templateTitle === "编辑模板内容") {
          this.contentList = this.contentList.map(item => {
            if(item.id === this.formModel.id) {
              return this.formModel;
            }
            return item;
          });
          Object.assign(this, {
            templateVisible: false,
            formModel: {},
            seriesId: [],
          });
          return;
        }

        let contentList = _.cloneDeep(this.contentList);
        const id = _.max(contentList.map(item => {
          return item.id;
        }));
        contentList = [
          ...contentList,
          {
            ...this.formModel,
            id: (id || 0) + 1
          }
        ];
        Object.assign(this, {
          contentList,
          templateVisible: false,
          formModel: {},
        });
      });
    },// 添加/编辑模板内容
    // handleGetSeriesList() {
    //   console.log(this.formModel.principalId);
    //   return this.formModel.principalId && this.carSeriesList[this.formModel.principalId] || [];
    // },
    handleFormData() {
      let params = _.cloneDeep(this.formState);
      let arr = _.cloneDeep(this.contentList);
      params.id = this.id;
      let Total = 0;
      const list = (arr || []).map(item => {
        Total += item.guideshootTotal;
        delete item.id;
        delete item.principalId;
        return item;
      });
      if(params.guideshootTotal == Total) {
        params.contentList = list;
        return params;
      } else {
        this.$message.error("下单模板数量不一致，请修改再提交");
      }
    },// params
    save() {
      this.$refs.formState.validate(valid => {
        if(valid) {
          if(!this.contentList.length) {
            this.$message.error("请先添加模板内容");
            return false;
          }
          const params = this.handleFormData();
          params.submitType = 1;
          let apis = this.id ? _api.updateOrder : _api.submitOrder;
          apis(params).then(res => {
            if(res.code == 200) {
              this.$message.success("工单保存成功");
              this.$router.push({
                name: "WorkOrderManagementList",
              });
            } else {
              this.$message.error(res.message || "工单保存失败");
            }
          });
        }
      });
    },// 保存
    SubmitButtons() {
      this.$refs.formState.validate(valid => {
        if(valid) {
          if(!this.contentList.length) {
            this.$message.error("请先添加模板内容");
            return false;
          }
          const params = this.handleFormData();
          params.submitType = 2;
          let apis = this.id ? _api.updateOrder : _api.submitOrder;
          apis(params).then(res => {
            if(res.code == 200) {
              this.$message.success("工单提交成功");
              this.$router.push({
                name: "WorkOrderManagementList",
              });
            } else {
              this.$message.error(res.message || "工单提交失败");
            }
          });
        }
      });
    },// 提交
    handleButtons() {
      this.resetForm();
      console.log("取消");
    },// 取消
    resetForm() {
      let that = this;
      this.$confirm({
        title: "当前填写内容尚未保存，确定要离开吗？",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          that.$router.go(-1);
        },
        onCancel() {
          console.log("取消");
        },
      });
    },
    actionChange(row) {
      this.actionButtons(2, row);
    }
  }
};
</script>

<style scoped lang="less">
.title_text {
  font-size: 16px;
  font-weight: bold;
}

.tools {
  display: flex;
  justify-content: flex-end;
}

.template_box {
  padding-top: 200px;
  display: flex;
  justify-content: flex-end;
}

.mr-10 {
  margin-right: 15px;
}
</style>
